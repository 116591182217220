body{
  background-color:rgb(255, 255, 255);
  padding: 0px 0px;
  /* font-family: "Josefin Sans", sans-serif; */
  font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
}   

.display-flex{
  display: flex;
}                           

/********************** TOP NAV  ****************************/
.top-nav{
  background:#1e382c;
  color: #ffffffbd;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  
}
.clg-code{
  columns: white;
  font-size: small;
  justify-content: center;
  display: flex;
}
.contact-info{
  display:flex;
  font-size: small;
  justify-content: space-between;
}
.contact-info-flex{
  display: flex;
}
.contact-item{
  padding: 3px 10px;
}
.info{
  padding-left: 5px;
}
@media (max-width:500px){
  .contact-info-flex{
    display: none;
  }
}
@media (max-width:450px){
  .contact-info {
    display: flex;
    font-size: x-small;
    max-width: 100%;
    justify-content: left;

}
.contact-item {
  padding: 3px 0px 3px 1px;
}
}
/******************** HEADER *************************/
.header{
  display: flex;
  background-color:rgb(9, 92, 43);
}
.flex{
  display:flex
}
.display-none{
  display: none;
}

.header-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(20 80 27);
  justify-content: center;
}
.sub-content-container {
  font-weight: 400;
  font-size: 0.8rem;
  display: flex;
}
.sub-content-container img{
  height: 2rem;
  margin: 0px 0.2rem;
}
.sub-content1{
  display: flex;
  align-items: center;
}
.sub-content2 {
  text-align: center;
  padding: 5px 0px;
  font-weight: 800;
  font-size: 1.3rem;
}
.sub-content3{
  font-size: 0.9rem;
  font-weight: 600;
}
.affiliation {
  font-size: 0.8rem;
  font-weight: 400;
}
@media (max-width:500px) {

   .display-none{
    display: flex;
   }
   .responsive{
    display:none
  }
  .flex{
    justify-content: center;
  }
  
}
/****************** NAVIGATION BAR *************************/

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  /* top: 146px; */
  z-index: 999;
  transition: background-color 0.3s;
  background-color: transparent; /* Initial background color */
}
/************************** NAVIGATION BAR  *******************************/
/* Navbar styling */
.ul {
  display: flex;
  justify-content: center;
  padding: 2px 20px;
  width: 100%;
  list-style-type: none;
  background-color: #def0d4;
}

.navigation-links {
  margin: 0.2em;
  padding: 0.1em 0.3em;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  transition: all 0.5s ease; /* Smooth transition */
}

.navigation-links:hover {
  background-color: rgb(50, 153, 55);
  color: #fff;
  box-shadow: 1px 2px 2px 1px rgba(170, 170, 170, 0.534);
  transform: scale(1.1);
  border-radius: 5px;
}

.navbars button {
  margin-left: 10px;
}

.active, .dot:hover {
  background-color: rgb(50, 153, 55);
  box-shadow: 1px 2px 2px 1px rgba(170, 170, 170, 0.534);
  transition: all 0.3s ease; /* Smooth transition */
  border-radius: 5px;
  font-size: larger;
  color: white;
}

/* Menu icon styling */
.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  padding-right: 40px;
  color: black;
  align-items: end;
  justify-content: right;
  position: relative; /* Make the icon relative for absolute positioning of dropdown */
}



@media (max-width: 500px) {
  .menu-icon {
    display: flex;
    background-color: #e6f5dc;
    width: auto;
    text-align: right; /* Aligns the icon to the right */
    /* padding-right: 20px; */
  }

  .ul {
    display: none;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 70px; /* Adjust based on navbar height */
    right: 0;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease; /* Smooth transition */
  }

  .ul.show {
    display: flex;
    position: absolute;
    transition: all 0.5s ease; /* Smooth transition */
    top: calc(100% + 5px); /* Position below the icon with a small gap */
    right: 20px;
  }

  .navigation-links {
    margin: 5px;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }
}

/* Additional responsive breakpoints */
@media (max-width: 500px) {
  .ul {
    top: 70px; /* Adjust based on navbar height */
  }

  .responsive-slider {
    margin-top: 135px;
  }
}

/* @media (max-width: 400px) {
  .ul.show {
    max-width: fit-content;
  }
} */

@media (max-width: 360px) {
  .ul.show {
    max-width: fit-content;
  }
}

/* Image and slider adjustments */
.width {
  height: 450px;
  padding: 0;
}

@media (max-width: 550px) {
  .width {
    height: 350px;
    padding: 50px 0 0 0;
  }
}

.slick-dots {
  position: absolute;
  bottom: 15px;
  width: 100%;
  list-style: none;
  text-align: center;
}

/*************************** HOME PAGE IMAGE *********************************/
.img-container{
  margin: 0px;
  position: relative;
  max-width: 100%; /* Ensure the container does not exceed the image width */
}
.Background-image {
  width: 100%;
  display: block;
  height: auto;
  margin-top: 8rem;
}
.text-overlay-main{
  background-color: rgb(255, 255, 255);
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 1.1em;
}
.text-overlay {
  display: flex;
  padding: 10px 10px;
  text-align: center; 
  justify-content: space-between;
 }
 
.header-logo{
  max-width: 100%;
  border-radius: 20px;
  height: 6em;
  width: auto;
  /* padding: 10px; */
  align-self: center;
  border-radius: 2em;
}
.single-card{
  /* display: flex; */
  /* responsive  */
  margin: 10px;
  width: 300px;
}
.home-cards{
  align-items: center;
  display:flex;
  justify-content: space-evenly;
}


.card-image{
  /* responsive  */
  width: 300px;
  height: 200px;
}

.card-body{
  padding: 10px;
  display: flex;
  flex-direction: column;
  line-height: 1.7;
  /* width: 400px; */
  /* width: 100%; */
}
.card-title{
  font-size: large;
  font-weight: 800;
  /* text-align: center; */
}
.card-p{
  font-size: 15px;
  /* width: 200px; */
  /* padding-left: 10px; */
  display: flex;
  width: 100%;
  text-align: justify;
}
@media (max-width:650px){
  .single-card{
    /* display: flex; */
    /* responsive  */
    margin: 10px;
    width: 275px;
  }
  .card-image{
    /* responsive  */
    width: 275px;
    height: 200px;
  }
  
}
@media (max-width:550px){
  .single-card{
    display:flex;
    flex-direction: column;
    width: 300px;
  }
  .card-image{
    /* responsive  */
    width: 300px;
    height: 200px;
  }
  .home-cards{
    display:flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-body {
    padding: 7px;
    display: flex;
    flex-direction: column;
    line-height: 1.7;
    width: 100%;
}
  .card-title{
    font-size: large;
    font-weight: 800;
    text-align: center;

  }
  .card-p{
    font-size: 15px;
    width: 100%;
    padding: 10px;
    display: flex;
    text-align: justify;
  }
}
.collaboration{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.collaboration-button {
  display: inline-block;
  padding: 8px 20px;
  margin: 0px 0;
  background-color: white;
  border: 2px solid green;
  border-radius: 5px;
  font-size: smaller;
  transition: all 0.3s ease-in-out;

}

.collaboration-link {
  display: flex;
  align-items: center;
  color: green;
  text-decoration: none;
}

.collaboration-button:hover {
  background-color: green;
  color: white;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  transform: scale(1.1);
}

.collaboration-link:hover {
  color: white;
}

.link-icon {
  margin-right: 8px;
}

.quote-section {
  position: relative;
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #e6f7e0;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.quote-section::before {
  content: open-quote;
  font-size: 100px;
  color: #3b913f;
  position: absolute;
  top: -10px;
  left: 10px;
}

.quote-text {
  font-size: 18px;
  font-style: italic;
  color: #333;
  margin: 0;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.quote-section::after {
  content: close-quote;
  font-size: 100px;
  color:#3b913f;
  position: absolute;
  bottom: -85px;
  right: 10px;
}
/*************** ABOUT US SECTION ***************************/
.about-us{
  margin-top: 124px;
  margin-bottom: 20px;
}

.list{
 display: flex;
 flex-direction:column ;
 padding-left: 170px;
 margin-bottom: 30px;
 padding-top: 25px;
}
.margin-top-0{
  margin-top: 0rem;
}
.about-us-heading{
  display: flex;
  justify-content: space-between;
  color: #cde5be;
  background-color: #12260f;
  align-items: center;
  font-weight: 900;
  text-align: center;
}
.about-us-text{
  align-content: center;
  font-size: xx-large;
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;

}
  
.about-us-image{
  max-width: 954px;
  height: 308px;
  justify-items: end;
}

.important-headings{
  display:flex;
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  color: white;
  background-color: #6a994e; /* Semi-transparent background */
  font-size: xx-large;
  justify-content: center;
  margin-bottom: 20px;
  padding: 20px;
  }
.bg-color{
  background-color: white;
}
.about-sections{
  display: flex;
  justify-content: space-between;
}

.paragraph{
  padding: 0px 50px 30px 50px;
  width: 100%;
  font-size: medium;
  color:  rgb(13, 88, 13);
  text-align: center;
  line-height: 2;
  margin-top: -190px;
}
.description{
  padding: 0px 50px;
  display: flex;
  justify-content: center;
  text-align: justify;
  line-height: 2;
}
.first-letter-larger::first-letter{
  font-size:xx-large;
  font-weight: bold;
  color: rgb(13, 88, 13);

}
.first-letter::first-letter{
  font-size: x-large;
  font-weight: bold;
  color: rgb(13, 88, 13);
  line-height: 1.6;
}

@media (min-width: 768px) {
  .about-sections {
      flex-direction: row; /* Display items side by side on larger screens */
  }

  .about-us-image {
      max-width: 85%; /* Ensure image does not exceed its container */
      height: auto; /* Maintain aspect ratio */
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
@media (max-width: 550px) {
  .about-sections {
    flex-direction: column; /* Display items side by side on larger screens */
}
.about-us-heading{
  display: flex;
  flex-direction: column;
}
.about-us-text{
  padding: 5px;
  font-size: x-large;
}
.about-us-image {
  align-self: center;
  max-width: 100%;
  height: auto;
}
.Background-image {
  width: 100%;
  display: block;
  height: auto;
  margin-top: 14rem;
}

}
/****************** About college  ***************************/
.about-college{
  margin-top: 181px;
}
.about-us-page-img{
  width: 100%;
  height: 350px;
  /* margin-top: 200px; */
}
.heading-about-us{
  box-shadow: 5px 3px 5px 2px #c7c6c6c9;
}
.bg-color {
  /* background-color: #8080801a; */
  padding: 0px 60px;
  border: none;
  text-align: center;
  font-size: xx-large;
  color: #375525;
  background-color: #639945ad;
  margin: 0px 0px;
}
.clg-info{
  display: flex;
}
.history-container{
  width: 60%;
  margin: 1rem;
  color: black;
}
@media (max-width: 900px){
  .about-college {
    margin-top: 300px;
}
}

.history-clg {
  margin: 1.5rem 0rem;
  font-size: 14px;
  text-align: justify;
}
.subHeading{
  font-size: larger;
  text-align: center;
  font-weight: 800;
  background: #def0d4;
   
}
.subheading-content{
  margin: 0px 90px ;
  border-radius: 10px;
  color: black;
  /* background-color: #e8fde8; */
  
}
.desc{
  line-height: 1.6;
  font-size: small;
  /* text-indent: 40px; */
}
/* Management table  */
.management-container {
  padding: 1rem;
  width: 40%;
  border: 1px solid #c2c0c0;
  background-color: #def0d447;
  margin: 1rem 1rem 1rem 0rem;
}

.management-container h1 {
  font-size: x-large;
  color: #337a28;
  font-weight: 500;
}

.management-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
 
}

.management-table td{
  color: black;
}

.management-table td:first-of-type{
  width: 200px;
}

.management-table th, .management-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.management-table th {
  background-color: #f2f2f2;
}
/* end manageent table  */

.desc strong{
  font-size: large;
}
.card-align{
  display: flex;
  justify-content: center;
}
.proffs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.proff-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  max-width: 550px;
  margin: 2rem;
  box-shadow: 1px 3px 5px 1px rgba(211, 211, 211, 0.644);
  background-color: #def0d447;
}
.president-card {
  display: flex;
    flex-direction: column;
    justify-content: center;
    border: 5px solid #8fbc75;
    text-align: center;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem; 
    margin: 0rem;
    box-shadow: 1px 3px 5px 1px rgb(188 188 188);
    background-color: white;
}
.proff-img{
  display: flex;
  justify-content: center;
  padding: 10px;
}
.proff-img img{
  height: 100px;
    width: 90px;
    border-radius: 0em;
    box-shadow: #0056b3;
    border: 2px solid white;
    box-shadow: 1px 3px 5px 1px  rgb(169 169 169)
}
.proff-name{
  padding: 5px;
  font-weight: 600;
  color: black;
}
.proff-edu {
  /* padding: 5px; */
  font-weight: 600;
  font-size: small;
  color: black;
}
.proff-desc{
  padding: 10px;
  font-size: small;
  color: #000000af;
  text-align: justify;
}


.table-container {
 
  margin: 2rem;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

}


table th{
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #b6b6b6;
  background-color: #1b4d1d;
  color: rgb(48, 138, 60);
  font-size: small;

}
 td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #b6b6b6;
  color: black;
  
}


tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:hover {
  background-color: #e9e9e9;
}

th {
  background-color: #4CAF50;
  color: white;
  position: sticky;
  top: 0;
  z-index: 2;
}

td:first-of-type, th:first-of-type {
  width: 50px;
}

@media (min-width: 200px) and (max-width: 550px){
  .about-college {
    margin-top: 230px;
}
  .clg-info{
    display:flex;
    flex-direction: column
  }
  .history-clg{
    margin: 20px 20px 20px 0px;
    font-size: 14px;
    text-align: justify;
    max-width: 90%;
  }
  .history-container{
    width: 100%;
    margin: 2rem;
   
  }
  .proff-card{
    
    border: 4px solid #bbe2a5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    margin: 0rem;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px 2px #aba8a8ab;

  }

  .table-container {
    margin: 20px 10px;
    overflow-x: auto;
    font-size: 10px;
  }
  .president-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 88%;
    padding: 10px;
    box-shadow: 1px 3px 5px 1px rgba(128, 128, 128, 0.644);
  }
  .management-container{
    width: 100%;
  }
}

/*************** COURSES *********************/

.courses-container{
  
  margin-top: 200px;
  /* display: flex; */
}
.course-inner-container {
  display: flex;
  margin: 90px 40px 40px 40px;
  padding: 2rem 2rem;
  border: 1px solid #b5b2b273;
  box-shadow: 1px 3px 5px 1px #b5b2b273;
}
.course-list{
  margin: 20px 5px;
}


@media (max-width:900px){
  .courses-container {
    margin-top: 300px;
    /* display: flex; */
}
  .course-inner-container{
    display: flex;
    /* flex-direction: column; */
    margin: 60px 10px 30px 10px;
    padding: 0px;
  } 
  .course-item {
    display: flex;
  }
  .course-details {
    padding: 10px;
    margin: 20px 10px;
}
}

@media (max-width:550px){
  .courses-container {
    margin-top: 254px;
    /* display: flex; */
}
  .course-inner-container{
    display: flex;
    flex-direction: column;
    margin: 60px 10px 30px 10px;
    padding: 0px;
  } 
  .course-item {
    display: flex;
  }
}

.courses-vertical-font{
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  margin-right: 20px;
  font-weight: 600;
}
.course-item {
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  padding: 10px 13px;
  font-weight: 400;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}


.course-item.selected {
  transform: translateY(-5px);
  box-shadow: 0 0 10px rgba(78, 78, 77, 0.507);
  background-color: #6a994e; 
  /* margin: 10px; */
  color: white; /* Text color changes to white on hover and when selected */
}

.course-details{
  padding: 20px;
  border-top-right-radius: 12em;
  border-bottom-right-radius: 1em;
  line-height: 2.2;
  background-color: #68b8543f;
  font-size: small;
  margin: 20px 30px;
  background-image: linear-gradient(#457445, #80b59a, #378c91);
  
}
.course-duration{
  font-size: 18px;
  color: black;
}
.course-desc{
  font-size: 14px;
  line-height: 2.5;
  color: #383737;
}
@media(max-width:550px){
  .course-details{
    margin: 20px 10px ;
    padding: 15px;
  }
}
/****************** ADMISSION******************************/

.admission-style {
  margin-top: 200px;
}

.admission-headings {
  width: 100%;
  justify-content: center;
  background-color: #ffffff;
  color: rgb(27, 75, 9);
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}

.course-item {
  cursor: pointer;
    padding: 5px;
    display: flex;
    font-weight: 600;
    align-items: center;
    margin: 0px 5px;
    justify-content: space-between;
    color: #000000;
}

.selected {
  font-weight: bold;
}

.arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.arrow-right::after {
  content: '►';
}

.arrow-down::after {
  content: '▼';
}

.title {
  font-size: medium;
  font-weight: 600;
  color: green;
}

.admission-description {
  padding-left: 15px;
  font-size: 0.9rem;
  line-height: 1.3;
}
.adission-description strong{
  margin-top: 10px;
}
.admission-description span{
  color: rgb(105, 105, 105);
}

.details-box-admission {
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  
  
}
.justify-content-around{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.admission-card {
  background-color: #f9f9f9;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  width:25rem
}

.detail-container.card {
  display: flex;
  flex-direction: column;
}


@media (max-width: 768px) {
  .admission-headings {
    /* flex-direction: column; */
    align-items: center;
    padding: 3px;
  }

  .course-item {
    width: 100%;
    text-align: center;
  }

  .admission-description {
    padding-left: 15px;
  }
}
@media(max-width:500px){
  .admission-style {
    margin-top: 250px;
}
.course-item {
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-item.selected {
  margin: 10px;
}
}
@media(min-width:501px) and (max-width:900px){
  .admission-style{
    margin-top: 290px;
  }
}
/********************* EVENTS  *********************************/


.events-container {
  display: flex;
  justify-content: space-around;
  margin: 10px 25px;
  flex-direction: column;
}

.events-list {
  display: flex;
  justify-content: center;
  color: rgb(82, 81, 81);
}

.event-item {
  cursor: pointer;
  padding: 5px 5px;
  margin: 0px 5px 20px 5px;
  background-color: #f4fff2;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  text-align: center;
  max-width: 150px;
  box-shadow: 1px 2px 4px 1px #808080ad;
}

.event-item.selected {
  background-color: rgb(50, 153, 55);
  color: white;
}
.event-main-title{
  padding: 10px;
  color: #22612a;
  font-size: x-large;
  font-weight: 900;
}
.events-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 2px 8px 1px #80808059;
}
/* featured events*/

.featured-event{
  max-width: 300px;
box-shadow: 1px 4px 4px 1px #7a7a7a7e;
border-radius: 10px;
}
.featured-event-image{
  max-width: 300px;
  height: 100%;
  overflow: hidden;
  box-shadow: 1px 4px 4px 1px #7a7a7a7e;
  border-radius: 10px;
}
.featured-event-image:hover{
  transform: scale(1.3);
  transition: 0.8s;
  box-shadow: 3px 4px 4px 2px #020202c9;
  border: 4px solid white;
  
}
.featured-event:hover{
  transition: transform 0.5s ease-in-out;
  
}
/* .featured-event-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

} */
.details-box1 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}

.inner-details-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 15px;
}

.event-card-horizontal {
  display: flex;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  /* max-width: 600px; */
  /* margin: 20px 20px 10px 20px; */
  overflow: hidden;
}

.event-image img {
  width: 750px;
  height: 100%;
  object-fit: cover;
}

.event-content {
  padding: 10px;
}
.event-content p{
  font-size: small;
  color: #666565;
  padding: 5px 0px;
  text-align: justify;
}
.months-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.month-item {
  cursor: pointer;
  padding: 10px 5px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.month-item.selected {
  background-color: rgb(50, 153, 55);
  color: white;
}
.upcomming-event-img {
  width: 170px;
  height: -webkit-fill-available;
}
.event-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0rem 0.5rem;
}
.upcomming-event-cards{
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color:rgb(243 243 243 / 17%);
  /* box-shadow: 1px 2px 5px 2px rgba(128, 128, 128, 0.596); */
}
.element-style {
  margin: 10px;
  border: 1px solid #c9c6c6;
}
.event-card {
  background-color: #fffefe;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0.5rem;
}
.event-card p{
  font-size: small;
  color: #666565;
  padding: 5px 0px;
  text-align: justify;
}
.view-more {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.link-icon {
  margin-right: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table th, table td {
  padding: 8px;
  border: 1px solid #ccc;
}

table th {
  background-color: #f0f0f0;
  font-weight: bold;
}
table td {
  font-size: small;
}
.view-more{
  display: flex;
}

.download {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.download-note{
  color: #5a5959;
  margin-left: 10px;
}
.drive-link-buttton {
  padding: 10px;
  background-color: #229130;
  color: white;
  border-radius: 10px;
  box-shadow: 6px 6px 5px 3px #8080806e;
  margin: 10px;
}

/**************************** TESTIMONIALS *****************************/

.testimonials-heading{
  font-size: x-large;
    color: #326b2a;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    padding: 5px 10px;
    border-style: dashed;
    font-weight: 700;
    width: auto;
    margin-top: 100px ;
    background-color: #e6f7e0
    /* box-shadow: 2px 1px 2px 1px gray; */
}
  .slider {
    position: relative;
    max-width: 100%;
    margin: auto;
    overflow: hidden;
}

.slides {
    display: flex;
    transition: 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 40px;
    align-items: center;
}

.navigation-arrow {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* transform: translateY(-50%); */
}

.prev, .next {
    cursor: pointer;
    padding: 16px;
    color: rgb(126 122 122);
    font-weight: bold;
    font-size: 18px;
}

.dots {
    text-align: center;
    padding: 10px;
    background: white;
}

.dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}
.student-img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.student-info{
  max-width: 650px;
  padding: 10px;
}
.student-name{
  padding: 5px 10px;
  font-size: x-large;
}
.student-desc{
  padding: 5px 20px;
  color: gray;

}

@media (max-width:500px){
  .testimonials-heading{
    font-size: x-large;
    margin-top: 50px ;
  }
  .student-name{
    padding: 5px 10px;
    font-size: large;
  }
  .student-desc{
    padding: 5px 20px;
    color: gray;
    font-size: small;
  }
  .slide {
    min-width: 100%;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
}

.prev, .next {
  cursor: pointer;
  padding: 10px;
  color: rgb(94, 92, 92);
  font-weight: bold;
  font-size: 18px;
}

.dots {
  text-align: center;
  background: white;
}

.dot {
  cursor: pointer;
  height: 5px;
  width: 5px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.student-img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
}
  
/***************** CONTACT US PAGE **************************/
.contactUs{
  display: flex;
  flex-direction: column;
  margin-top: 160px;
  margin-left: 20px;
}
.contact-information{
  display: flex;
  justify-content:space-around
}
  
  .location{
      margin: 30px 10px 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .location-img{
    width: 90px;
    /* border: 1px solid red; */
    /* padding-right: 20px; */
  }
  .location-div{
    padding-right: 10px;
    text-align: center;
  }
  /* src/ContactUs.css */
  
  .map-container {
    margin-top: 50px;
    /* border: 2px solid black; */
    background-image: linear-gradient(#457445, #80b59a, #378c91);
    padding: 5px;
}
  
  /*** contact-us-form***/
  .form-heading{
    text-align: center;
    font-weight: 800;
    color: #eaf9e8;
    font-size: x-large;
  }
  .map-form-container{
    display: flex;
    justify-content: center;
  }
  .form-container {
    width: 280px;
    /* height: 400px; */
    margin: 53px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-image: linear-gradient(#457445, #b8d5a3, #d3f7b9);
}
.form-container label,
.form-container input {
    display: block;
    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
}
.form-container input{
  padding-left: 5px;
}
.form-container textarea{
    width: 100%;
    height: 30px;
    min-height:70px
}
.form-container button{
  width: 100%;
  background-color: rgb(31 73 31);
  color: #ffffff;
  height: 30px;
  border-radius: 5px;
  margin: 10px 0px 0px 0px;

}
.form-container input[type="submit"] {
    width: auto;
    cursor: pointer;
}
  
/******************* CONTACT US-FOOTER  *********************/
.footer-image{
  width: 100%;
  margin-top: 0px; 
}
.width-350{
  width: 300px;
}
.display-col{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contact-main-container{
  display: flex;
  background-color: #1d1d1d;
  justify-content: space-between;
  color: rgba(247, 244, 240, 0.808);
  padding: 20px;
  margin-top: 50px;
  
}
.headings{
  margin-bottom: 20px;
  font-size: large;
}

.other-links{
  text-decoration: none;
  color: #c9c7c7b7;
  font-size:small;
  text-wrap: wrap;
}
.other-links:hover{
  color: rgb(116, 211, 22);
}

.contact-container{
  padding-left: 20px;
} 
.quote{
  padding-left: 7px;
}
.quote-content{
  font-size: small;
}
.lists table{
  border-collapse: collapse;
  line-height: 1.6;
  margin-top: 10px;
  line-height: 2.5;
  font-size: small;
  color: #acacacb7;
  text-wrap: wrap;
  border-collapse: collapse;
}
.lists td {
  border: none; /* Remove all borders from table cells */
  padding: 8px; /* Adjust cell padding */
}

.social-link{
  display: flex;
  font-size: small;
  color: #acacacb7;
    
}
.contacts{
  color: #bebebeb7;
}
@media (max-width: 450px) {
  .headings {
    margin: 20px 0px 5px 5px;
    font-size: medium;

}
.contact-main-container {
  display: flex;
  flex-direction: column;
  background-color: #1d1d1d;
  justify-content: space-between;
  color: rgba(247, 244, 240, 0.808);
  padding: 20px;
  margin-top: 30px;
}
.contact-container {
    padding-left: 0px;
}
.quote-content {
  font-size: small;
}
}

/*********************************** FOOTER *****************************/
.footer{
  background-color: #000000;
  color: rgba(250, 235, 215, 0.733);
  font-size: small;
  display: flex;
  justify-content: center;
  padding: 20px;
}


@media (max-width:500px) {
  .body{
    width: 100%;
  }
  .list{
    padding: 0px 40px ;
  }
  .description{
    padding: 0px 40px;
  }
  .header-content{
    font-size: small;
    padding: 0px;
  }
  
.text-overlay {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  text-align: center; 
}

  .header-logo{
    height: 40px;
    padding: 2px 0px 0px 0px;
    border-radius: 0px;
    margin: 0px;
  }
  .sub-content1{
   padding: 0px;
   margin: 0px;
   font-weight: normal;
   font-size: xx-small;

  }
  .sub-content2 {
    font-weight: normal;
    font-size: medium;
    font-weight: 900;
    padding: 5px 10px 0px 10px;
}
  .sub-content3{
    font-size: smaller;
}
  
  .card-style{
    display:flex;
    flex-direction: column;
    width: 350px;
    justify-content: center;
    margin-bottom: 70px;
    padding: 20px;
    
  }

  .figure{
    display: flex;
    justify-content: center;
  }
  .card-alignments{
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }

  .location {
    /* border: 1px solid black; */
    display: flex;
    margin: 10px ;
    align-items: center;
}
  .contactUs {
    display: flex;
    flex-direction: column;
    margin-top: 240px;
}
  .location-img{
    width: 100px;
    /* border: 1px solid red; */
    padding-right: 20px;
  }
  .location-div{
    padding-right: 0px;
  }
  .form-container {
    /* width: 280px; */
    /* height: 400px; */
    margin: 5px;
    padding: 15px;
    align-self: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.map-container {
  /* margin-top: 20px; */
  width: 390px;
  /* height: 450px; */
  overflow: hidden;
  margin-bottom: 20px;
  padding-right: 5px;
}  
  .map-form-container{
    display: flex;
    flex-direction: column
  }
  .headingText{
    left:30%
    }
    
    .contact-information {
      display: flex;
      flex-direction: column
  }
 
}


@media (min-width: 500px) and (max-width: 900px) {


/* .header-logo {
  height: 3.5rem;
  padding: 2px 0px 0px 0px;
  border-radius: 0px;
  margin: 0px;
}
  .sub-content2{
      font-weight: 500;
      font-size: medium;
      padding: 5px 10px 0px 10px;
  }
  .sub-content1{
      padding: 0px;
      margin: 0px;
      font-weight: normal;
      font-size: small;
   
     } */
  .about-us-text{
      padding: 50px;
    }
  .list{
      padding-left: 60px;
    }
}




/********************************* CAMPUS LIFE  *********************************/


.campus-life-container {
  display: flex;
  margin-top: 200px;
  padding: 0px 30px;
}

.sections-list {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  border: 1px solid rgb(240, 237, 237) ;
  background-color:#def0d47a;
  border-radius: 5px;
  padding: 10px;
}

.section-item {
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.section-item:hover{
  box-shadow: 1px 2px 2px 1px rgba(170, 170, 170, 0.534); /* Shadow effect */
  transform: scale(1.1); /* Scale effect */
  transition: 0.5s;
  border-radius: 5px;
  background-color: rgb(50, 153, 55); /* Blue background */
  color: #fff; /* White text color */
}

.section-item.selected {
  box-shadow: 1px 2px 2px 1px rgba(170, 170, 170, 0.534); /* Shadow effect */
  /* transform: scale(1.1); */
  background-color:rgb(50, 153, 55);
  color: white;
  border-radius: 5px;
}

.section-details {
  flex-grow: 1;
}

.details-box {
  padding: 20px;
  background-color: #def0d47a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}
.nss-file{
  display: flex;
  justify-content: end;
  margin: 10px 5px;
}
.nss-button{
  background-color: #256127;
  color: white;
  padding: 3px 5px;
  border-radius: 7px;
  margin: 0px 10px ;
}

@media (max-width:550px){
  .sections-list {
    margin: 0px 0px 10px 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
  .sub-description{
    padding: 0px;
  }

.campus-life-container {
  flex-direction: column;
  display: flex;
  margin-top: 255px;
  padding: 0px 20px;
}


.section-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: x-small;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}
.section-item:hover{
  box-shadow: 1px 2px 2px 1px rgba(170, 170, 170, 0.534); /* Shadow effect */
  transform: scale(1.1); /* Scale effect */
  transition: 0.5s;
  border-radius: 5px;
  background-color: rgb(50, 153, 55); /* Blue background */
  color: #fff; /* White text color */
}

.section-item.selected {
  box-shadow: 1px 2px 2px 1px rgba(170, 170, 170, 0.534); /* Shadow effect */
  /* transform: scale(1.1); */
  background-color:rgb(50, 153, 55);
  color: white;
  border-radius: 5px;
  padding: 3px 3px;
}

.section-details {
  flex-grow: 1;
}

.details-box {
  padding: 10px;
  background-color: #def0d47a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}
 /*********events responsiveness *******/

 .events-container {
  display: flex;
  justify-content: space-around;
  margin: 0px;
  flex-direction: column;
}

  .events-list {
    display: flex;
    justify-content: center;
  }

  .event-item {
    cursor: pointer;
    padding: 0px 5px;
    margin: 0px 5px 20px 5px;
    background-color: #f4fff2;
    border-radius: 5px;
    text-align: center;
    font-size: small;
    max-width: 150px;
    align-content: center;
    box-shadow: 1px 2px 4px 1px #808080ad;
}

  .event-item.selected {
    background-color: rgb(50, 153, 55);
    color: white;
  }
  .event-main-title{
    padding: 10px;
    color: #22612a;
    font-size: x-large;
    font-weight: 900;
  }
  .events-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 2px 8px 1px #80808059;
  }

  .details-box1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inner-details-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    background-color: whitesmoke;
  }

  .event-card-horizontal {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    /* max-width: 600px; */
    margin: 10px;
    overflow: hidden;
}

  .event-image img {
    width: 850px;
    height: 220px;
    /* object-fit: cover; */
  }

  .event-content {
    padding: 10px;
  }
  .event-content p{
    font-size: small;
    color:rgb(57 56 56);
    padding: 5px 0px;
    text-align: justify;
  }
  .months-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
  }

  .month-item {
    cursor: pointer;
    padding: 5px;
    margin: 3px 3px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 5px;
}

  .month-item.selected {
    background-color: rgb(50, 153, 55);
    color: white;
}
  .upcomming-event-img {
    width: 352px;
    height: auto;
}
  .event-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0rem 0.5rem;
  }
  .upcomming-event-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: rgb(243 243 243 / 17%);
}
  .element-style {
    margin: 10px;
    border: 1px solid #c9c6c6;
  }
  .event-card {
    background-color: #fffefe;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0.5rem;
  }
  .event-card p{
    font-size: small;
    color: #666565;
    padding: 5px 0px;
    text-align: justify;
  }
  .view-more {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .link-icon {
    margin-right: 5px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }

  table th, table td {
    padding: 8px;
    border: 1px solid #ccc;
  }

  table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  table td {
    font-size: small;
  }
  .view-more{
    display: flex;
  }

}
/*//////////////   cards //////////////////////*/
.sub-headings {
  font-size: large;
  font-weight: 700;
  color: #1b4d1d;
  display: flex;
  justify-content: center;
  text-align: center;
}
.sub-description {
  padding: 10px;
  display: flex;
  /* justify-content: center; */
  text-align: justify;
  font-weight: 350;
  font-size: medium;
  color:rgb(57 56 56);
}

.sub-description::first-letter{
  font-size:xx-large;
  font-weight: bold;
  color: rgb(13, 88, 13);

}

.sub-card-container {
  margin: 5px;
  display: flex;
  flex-wrap: wrap; /* Ensure cards wrap to next line */
  justify-content: center; /* Center the cards */
}

.sub-cards {
  background-color: white;
  width: 150px;
  height: auto;
  margin: 10px; /* Add margin between cards */
  box-sizing: border-box; /* Ensure padding and border are included in the total width and height */
}

.sub-card-image {
  width: 150px;
  height: 160px;
  border-radius: 15px;
  box-shadow: 1px 4px 4px 1px #7a7a7a7e;
  overflow: hidden; /* Ensure image fits within the container */
}
.sub-card-image:hover{
  transform: scale(1.7);
  transition: 0.8s;
  box-shadow: 3px 4px 4px 2px #020202c9;
  border: 4px solid white;
}
.sub-card-title{
  font-size: x-large;
  font-weight: 300;
}
.sub-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure image covers the entire container */
}
@media (max-width:450px){
  .sub-cards {
    background-color: white;
    width: 140px;
    height: auto;
    margin: 5px;
    box-sizing: border-box;
}
.sub-headings {
  font-size: medium;
  font-weight: 700;
  color: #1b4d1d;
  display: flex;
  justify-content: center;
  text-align: center;
}
.sub-card-image {
  width: 140px;
  height: 160px;
  border-radius: 15px;
  box-shadow: 1px 4px 4px 1px #7a7a7a7e;
  overflow: hidden;
}
.sub-card-container {
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
}
/******************************* field work ************/
.field-work-container {
  display: flex;
  margin: 20px;
}

.field-work-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-work-image {
  margin-bottom: 20px;

}

.field-work-image img {
  width: 150px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 1px 4px 4px 1px #7a7a7a7e;
}

.field-work-description {
  flex: 2;
  padding: 20px;
  text-align: justify;
}

.field-work-description h2 {
  font-size: x-large;
  font-weight: 700;
  color: #1b4d1d;
  text-align: center;
  margin-bottom: 20px;
}

.field-work-description h3 {
  font-size: large;
  font-weight: 600;
  color: #030303;
  margin-top: 20px;
}

.field-work-description p {
  font-size: 14px;
  font-weight: 350;
  margin-bottom: 10px;
}


.block{
  font-weight: 400;
  font-size:large ;
  display: contents;
  color: #2b8f38;
}
@media (max-width:450px){
  .field-work-description h2 {
    font-size: medium;
    font-weight: 700;
    color: #1b4d1d;
    text-align: center;
    margin-bottom: 10px;
}
.field-work-image {
  margin-bottom: 20px;
  width: 100px;
  height: 160px;
}
.field-work-container {
  display: flex;
  margin: 0px;
}
.field-work-description {
  flex: 2 1;
  padding: 0px 0px 0px 10px;
  text-align: justify;
}
}
 /*************************************** NOTICE BOARD **************************/
 .notice{
  margin: 200px 10px 0px 10px;
  background-color: #ffffff;
 }
 .notice-cards{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:rgb(243 243 243 / 17%);
  margin: 30px 0px;
  padding: 20px;
  box-shadow: 1px 2px 5px 2px rgba(128, 128, 128, 0.596);
 }
 .sub-card-title{
  font-size: xx-large;
  font-weight: 800;
  padding: 5px;

 }
 .sub-card-dated{
  font-size: medium;
  color: gray;
  font-weight: 400;
}
.sub-card-desc{
  font-size: medium;
  color: gray;
}
.sub-card-button{
  color: white;
  background-color: rgb(16, 116, 38);
  font-size: larger;
  padding: 5px 10px;
 margin-top:10px ;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 2px rgba(128, 128, 128, 0.596);
}
@media (max-width:550px){
  .notice {
    margin: 255px 10px 0px 10px;
    background-color: #ffffff;
}
.sub-card-title {
  text-align: center;
  font-size: x-large;
  font-weight: 800;
  padding: 5px;
  justify-content: center;
}

}

/************** navigation bar final adjustments  *********************/
/* @media(max-width:900px){
  .navbar{
    top: 156px;
  }
} */
/* @media(max-width:767px){
  .navbar{
    top: 180px;
  }
} */
/* @media (min-width:501px) and (max-width:550px){
  .navbar{
    top: 165px;
  }
} */
/* @media(max-width:500px){
  .navbar{
    top: 172px;
  }
} */

/************** Image slidder final adjustments  *********************/
@media(max-width:900px){
  .responsive-slider{
    top: 156px;
  }
}
@media(max-width:550px){
  .responsive-slider{
    top: 125px;
  }
}
@media(min-width:501px) and (max-width:550px){
  .responsive-slider{
    top: 0px;
    margin-top: 7rem;
  }
}
@media(max-width:500px){
  .responsive-slider{
    top: 100px;
  }
}

/************** college name heading final adjustments  *********************/
@media (min-width:501px) and (max-width:550px){

  .sub-content1{
    display: flex;
    align-items: center;
  }
  .sub-content2 {
    text-align: center;
    padding: 5px 0px;
    font-weight: 800;
    font-size:1rem;
  }
  .sub-content3{
    font-size: 0.9rem;
    font-weight: 600;
  }
  .affiliation {
    font-size: 0.6rem;
    font-weight: 400;
  }
  .header-logo {
    max-width: 100%;
    border-radius: 20px;
    height: 4em;
    width: auto;
    /* padding: 10px; */
    align-self: center;
    border-radius: 2em;
}
}

