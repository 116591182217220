/* LogoutButton.css */
.logout-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.logout-button:hover {
    background-color: #d32f2f;
}

/* OngoingEventForm.css */
.event-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}

.event-form div {
    margin-bottom: 15px;
}

.event-form label {
    margin-bottom: 5px;
    font-weight: bold;
}

.event-form input,
.event-form textarea {
    padding: 10px;
    font-size: 14px;
    width: 100%;
}

.event-list {
    margin-top: 20px;
}

/* .event-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
} */

.event-item h3 {
    margin-top: 0;
}

.event-image {
    max-width: 100%;
    margin-top: 10px;
}

/* Centering the alert */
.swal2-popup {
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 50% !important;
}
