.login-container{
    display: flex;
    margin-top: 200px;
    width: 100%;
    justify-content: center;

}
.admin-login-form{
    
    width: 300px;
    border: 1px solid rgb(23, 99, 23);
    padding: 20px;
    background-color: rgb(229, 250, 229);

}
.admin-login-form h1{
    font-size: x-large;
    text-align: center;
    font-weight: 600;
    padding: 5px 10px;
    width: 100%;
    background-color: rgb(6, 92, 6);
    color: white;
}
.admin-login-container{
    display: flex;
    flex-direction: column;

}
.admin-login-field{
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
}
.admin-input{
    border: 1px solid rgb(187, 186, 186);
}
.login-submit{
    background-color: rgb(10, 95, 10);
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 10px 10px;
}